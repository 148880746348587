<template>
	<el-dialog
		title="Task Detail"
		:visible.sync="state.open"
		width="90%"
		center
	>
		<div class="d-flex w-100 flex-column" v-if="state.task">
			<div class="d-flex w-100">
				<div style="width: 170px" class="p-1 border">Task</div>
				<div class="flex-grow-1 p-1 border">
					{{ ticketKey }}
				</div>
			</div>

			<div class="d-flex w-100">
				<div style="width: 170px" class="p-1 border">Title</div>
				<div class="flex-grow-1 p-1 border">
					{{ title }}
				</div>
			</div>

			<div class="d-flex w-100">
				<div style="width: 170px" class="p-1 border">Change Logs</div>
				<div class="flex-grow-1 p-1 border d-flex flex-column">
					<div class="d-flex w-100">
						<div
							class="text-center border p-1"
							style="width: 250px"
						>
							Author
						</div>
						<div
							class="text-center border p-1"
							style="width: 200px"
						>
							Status
						</div>
						<div
							class="text-center border p-1"
							style="width: 180px"
						>
							Created At
						</div>
						<div class="text-center border p-1 flex-grow-1">
							Remark
						</div>
					</div>

					<div
						v-for="(changeLog, i) in changeLogs"
						:key="i"
						class="d-flex w-100"
					>
						<div
							class="text-center border p-1"
							style="width: 250px"
						>
							<img
								style="width: 24px"
								:src="changeLog.author.avatarUrl"
							/>
							<span class="pl-1">{{
								changeLog.author.displayName
							}}</span>
						</div>
						<div
							class="text-center border p-1"
							style="width: 200px"
						>
							{{ changeLog.toStatusName }}
						</div>
						<div
							class="text-center border p-1"
							style="width: 180px"
						>
							{{ formatDateTime(changeLog.createdAt) }}
						</div>

						<div class="text-center border p-1 flex-grow-1">
							<div
								v-if="
									changeLog.hasRevisedCreatedAt &&
									changeLog.toStatusName == 'IN_PROGRESS'
								"
								class="bg-warning text-white"
							>
								<i>
									(Non-working hour, assume the start time on
									{{
										formatDateTime(
											changeLog.revisedCreatedAt
										)
									}})
								</i>
							</div>

							<div
								v-else-if="
									changeLog.hasRevisedCreatedAt &&
									changeLog.toStatusName == 'DEV_DONE'
								"
								class="bg-warning text-white"
							>
								<i>
									(Non-working hour, assume the completion
									time on
									{{
										formatDateTime(
											changeLog.revisedCreatedAt
										)
									}})
								</i>
							</div>

							<div v-if="changeLog.remark != null">
								{{ changeLog.remark }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="d-flex w-100">
				<div style="width: 170px" class="p-1 border">
					Actual Man Hours
				</div>
				<div
					class="flex-grow-1 p-1 border"
					v-if="actualManHours != null"
				>
					{{ actualManHours }} hour
				</div>
				<div class="flex-grow-1 p-1 border" v-else>
					<i>(Missing input time record)</i>
				</div>
			</div>

			<div class="d-flex w-100">
				<div style="width: 170px" class="p-1 border">
					Pro-Rate Hours
				</div>
				<div class="flex-grow-1 p-1 border">
					<div class="d-flex w-100">
						<div
							style="width: 110px"
							class="border text-center p-1"
						>
							Working Date
						</div>
						<div style="width: 200px" class="border text-right p-1">
							Status Holding Hours
						</div>
						<div style="width: 250px" class="border text-right p-1">
							Acutal Man Hours (Pro-Rate by Status Holding Hours)
						</div>
					</div>
					<div
						v-for="(log, i) in actualManHourLogs"
						:key="i"
						class="d-flex w-100"
					>
						<div
							style="width: 110px"
							class="border text-center p-1"
						>
							{{ log.date }}
						</div>
						<div style="width: 200px" class="border text-right p-1">
							{{ log.statusHoldingHour }} Hour
						</div>
						<div style="width: 250px" class="border text-right p-1">
							{{ log.ratio }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { computed } from "@vue/composition-api";
	import dayjs from "dayjs";

	export default {
		props: {
			state: {
				type: Object,
			},
		},
		setup(props, ctx) {
			// REQUIREMENT, TO_DO, IN_PROGRESS, DEV_DONE, QA_FAIL, UAT_FAIL, READY_FOR_UAT, WAITING_FOR_GO_LIVE, DONE, CANCELLED
			const formatDateTime = (dayjsObject) => {
				return dayjsObject.format("YYYY-MM-DD HH:mm:ss");
			};

			// dayjs object
			const findActualWorkingDay = (start, end) => {
				const endDayEnd = end.hour(23).minute(59).second(59);
				let pointer = start.hour(0).minute(0).second(0);
				let days = [];

				while(pointer.isBefore(endDayEnd)) {
					// Accepts numbers from 0 (Sunday) to 6 (Saturday)
					if(pointer.day() >= 1 && pointer.day() <= 5) {
						days.push(pointer)
					}
					pointer = pointer.add(1, 'day');
				}

				return days;
			}

			// dayjs object
			const reviseDay = (createdAt, status) => {
				let newCreatedAt = createdAt;
				let hasRevisedCreatedAt = false;

				// correct working hour from 9 to 18
				if (createdAt.hour() < 9) {
					newCreatedAt = createdAt.hour(9).minute(0).second(0).millisecond(0);
					hasRevisedCreatedAt = true;
				} else if (createdAt.hour() > 18 || (createdAt.hour() == 18 && (createdAt.minute() > 0 || createdAt.second() > 0 || createdAt.millisecond() > 0))) {
					newCreatedAt = createdAt.hour(18).minute(0).second(0).millisecond(0);
					hasRevisedCreatedAt = true;
				}

				// offset day if it is not weekday
				if(status == 'IN_PROGRESS') {
					// to next monday
					let offset = 0;
					if(newCreatedAt.day() == 0) {
						offset = 1;
					} else if(newCreatedAt.day() == 6) {
						offset = 2;
					}
					newCreatedAt = newCreatedAt.add(offset, 'day');
					
					if(offset != 0) {
						hasRevisedCreatedAt = true;
					}
				} else if(status == 'DEV_DONE') {
					// to previous friday
					let offset = 0;
					if(newCreatedAt.day() == 0) {
						offset = 2;
					} else if(newCreatedAt.day() == 6) {
						offset = 1;
					}
					newCreatedAt = newCreatedAt.subtract(offset, 'day');

					if(offset != 0) {
						hasRevisedCreatedAt = true;
					}
				}

				return { hasRevisedCreatedAt, revisedCreatedAt: newCreatedAt };
			}

			const ticketKey = computed(() => props.state.task.key);
			const title = computed(() => props.state.task.summary);
			const actualManHours = computed(() => props.state.task.actualManHours);

			const changeLogs = computed(() => {
				let firstInProgress = null;
				let lastDevDone = null;

				let newChangeLogs = props.state.task.changeLogs.map((log) => {
					let createdAt = dayjs(log.createdAt).local();
					let revisedCreatedAt = createdAt;
					let hasRevisedCreatedAt = false;

					if (log.toStatusName == "IN_PROGRESS" || log.toStatusName == "DEV_DONE") {
						const reviseDayState = reviseDay(createdAt, log.toStatusName);
						revisedCreatedAt = reviseDayState.revisedCreatedAt;
						hasRevisedCreatedAt = reviseDayState.hasRevisedCreatedAt;
					}

					let newLog = {
						...log,
						createdAt: createdAt,
						revisedCreatedAt: revisedCreatedAt,
						hasRevisedCreatedAt,
						remark: null,
					};

					// Find first last development cycle
					if (log.toStatusName == "IN_PROGRESS") {
						// Not a first in progress, therefore, clean up
						if(firstInProgress != null) {
							newLog.revisedCreatedAt = newLog.createdAt;
							newLog.hasRevisedCreatedAt = false;
						}

						if(firstInProgress == null) {
							firstInProgress = newLog;
						}
					}

					if (log.toStatusName == "DEV_DONE") {
						// Not last dev done, therefore, clean up
						if(lastDevDone != null) {
							lastDevDone.revisedCreatedAt = lastDevDone.createdAt;
							lastDevDone.hasRevisedCreatedAt = false;
						}

						lastDevDone = newLog;
					}

					return newLog;
				});

				if (firstInProgress != null) {
					firstInProgress.remark = "Development Start";
				}

				if (lastDevDone != null) {
					lastDevDone.remark = "Development Completed";
				}

				return newChangeLogs;
			});

			const actualManHourLogs = computed(() => {
				let start = null;
				let end = null;

				props.state.task.changeLogs.forEach((log) => {
					if (log.toStatusName == "IN_PROGRESS" || log.toStatusName == "DEV_DONE") {
						const { hasRevisedCreatedAt, revisedCreatedAt } = reviseDay(dayjs(log.createdAt).local(), log.toStatusName);

						if (log.toStatusName == "IN_PROGRESS" && start == null) {
							start = revisedCreatedAt;
						}

						if (log.toStatusName == "DEV_DONE") {
							end = revisedCreatedAt;
						}
					}
				});

				let logs = [];
				if (start != null && end != null) {
					let actualWorkingDays = findActualWorkingDay(start, end);
					let totalDiff = 0;
					for (let i = 0; i < actualWorkingDays.length; i++) {
						let diff;
						if (i == 0) {
							diff = actualWorkingDays[i].hour(18).minute(0).second(0).millisecond(0).diff(start, "hour", true);
						} else if (i == actualWorkingDays.length - 1) {
							diff = end.diff(actualWorkingDays[i].hour(9).minute(0).second(0).millisecond(0), "hour", true);
						} else {
							diff = 9;
						}
						totalDiff += diff;

						logs.push({
							date: actualWorkingDays[i].format("YYYY-MM-DD"),
							statusHoldingHour: diff,
							ratio: "Missing actual man hours",
						});
					}

					logs.forEach((log) => {
						if (props.state.task.actualManHours != null) {
							log.ratio =
								(
									(log.statusHoldingHour / totalDiff) *
									props.state.task.actualManHours
								).toFixed(2) + " Hour";
						}

						log.statusHoldingHour = log.statusHoldingHour.toFixed(2);
					});
				}

				return logs;
			});

			return {
				ticketKey,
				title,
				actualManHours,
				changeLogs,
				actualManHourLogs,

				formatDateTime,
			};
		},
	};
</script>

<style scoped>
</style>