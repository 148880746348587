<template>
	<b-tab title="Missing Time Record">
		<el-table :data="tableData" style="width: 100%" border>
			<el-table-column prop="ticket" label="Ticket" width="120">
				<template slot-scope="scope">
					<a :href="`https://timesitteam.atlassian.net/browse/${scope.row.ticket}`" target="ticket" >{{ scope.row.ticket }}</a>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="Status" width="150"/>
			<el-table-column prop="assignee" label="Assignee">
				<template slot-scope="scope">
					<div>
						<img style="width: 32px" :src="scope.row.assignee.avatarUrls" />
						<span class="pl-1">{{ scope.row.assignee.displayName }}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="inProgress" label="In Progress" width="160" />
			<el-table-column prop="devDone" label="DEV done" width="160" />
			<el-table-column prop="summary" label="Summary" />
		</el-table>
	</b-tab>
</template>

<script>
	import { BTab } from "bootstrap-vue";
	import { computed } from "@vue/composition-api";

	export default {
		components: {
			BTab
		},
		props: {
			task_list: {
				type: Array,
			},
		},
		setup(props, ctx) {
			const tableData = computed(() => {
				return props.task_list.map((task) => {
					return {
						ticket: task.key,
						status: task.fields.status.name,
						assignee: {
							avatarUrls: task.fields.assignee.avatarUrls["48x48"],
							displayName: task.fields.assignee.displayName,
						},
						inProgress: task.status_time.in_progress,
						devDone: task.status_time.dev_done,
						summary: task.fields.summary,
					};
				});
			});

			return {
				tableData,
			};
		},
	};
</script>

<style scoped>
</style>