<template>
	<div>
		<el-table
			:data="tableData"
			style="width: 100%"
			border
			:cell-style="{ verticalAlign: 'top' }"
			header-align="center"
			:height="(height - 220) + 'px'"
		>
			<el-table-column prop="member" label="Member" width="200px" fixed>
				<template slot-scope="scope" slot="header">
					<div style="text-align: center !important">
						{{ scope.column.label }}
					</div>
				</template>

				<template slot-scope="scope">
					<div
						style="
							display: flex;
							flex-direction: column;
							align-items: center;
						"
					>
						<img width="32px" :src="scope.row.member.avatarUrl" />
						<div>
							<b>{{ scope.row.member.displayName }}</b>
						</div>
					</div>
				</template>
			</el-table-column>

			<template v-for="header in tableHeader">
				<el-table-column
					width="250px"
					:prop="header.week"
					:key="header.week"
					:label="header"
				>
					<template slot-scope="scope" slot="header">
						<div>
							<div style="text-align: center !important">
								{{ scope.column.label.week }}
							</div>
							<div style="text-align: center !important">
								{{ scope.column.label.start }} -
								{{ scope.column.label.end }}
							</div>
						</div>
					</template>

					<template slot-scope="scope">
						<div>
							<div
								class="
									d-flex
									border-bottom
									font-weight-bold
									bg-success
									text-white
									w-100
								"
							>
								<div class="flex-grow-1 px-1">Total Time :</div>
								<div
									class="text-right px-1"
									style="width: 80px"
								>
									{{ scope.row[header.week].total }}
								</div>
							</div>

							<div
								v-for="task in scope.row[header.week].taskList"
								:key="task.key"
								class="d-flex"
							>
								<div class="flex-grow-1 px-1 d-flex">
									<span
										class="d-flex align-items-center pr-1"
										role="button"
										@click="loadInfo(task)"
									>
										<feather-icon
											icon="InfoIcon"
											size="16"
										/>
									</span>
									<a
										:href="`https://timesitteam.atlassian.net/browse/${task.key}`"
										target="ticket"
										>{{ task.key }}</a
									>
								</div>
								<div
									class="text-right px-1"
									style="width: 80px"
									v-if="task.proRateActualManHours != null"
								>
									{{ task.proRateActualManHours.toFixed(2) }}h
								</div>
								<div
									class="text-right text-white px-1"
									style="
										width: 80px;
										background-color: #cc0000;
									"
									v-else-if="
										task.inProcess == null &&
										task.devDone != null
									"
								>
									<i>無開始</i>
								</div>
								<div
									class="text-right text-white px-1"
									style="
										width: 80px;
										background-color: #0b5394;
									"
									v-else-if="
										task.inProcess != null &&
										task.devDone == null
									"
								>
									<i>無结束</i>
								</div>
								<div
									class="
										text-right text-white
										px-1
										bg-warning
									"
									style="width: 80px"
									v-else
								>
									<i>無時數</i>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
			</template>
		</el-table>

		<jira-task-detail-model :state="dialogState" />
	</div>
</template>

<script>
	import { BTab } from "bootstrap-vue";
	import { computed, reactive, onMounted, ref, onUnmounted } from "@vue/composition-api";
	import JiraTaskDetailModel from "./JiraTaskDetailModel.vue";
	import dayjs from "dayjs";
	import _ from 'lodash';

	export default {
		components: {
			BTab,
			JiraTaskDetailModel,
		},
		props: {
			report: {
				type: Object,
			},
		},
		setup(props, ctx) {
			const dialogState = reactive({ open: false, task: null });
			const height = ref(window.innerHeight);

			onMounted(() => {
				window.addEventListener("resize", () => {
					height.value = window.innerHeight;
				});
			});

			onUnmounted(() => {
				window.removeEventListener("resize");
			})

			const tableHeader = computed(() => {
				return Object.keys(props.report.weeklyReport).map((yearWeek) => {
					const data = props.report.weeklyReport[yearWeek];
					return {
						week: yearWeek,
						start: dayjs(data.from).format("YYYY-MM-DD"),
						end: dayjs(data.to).format("YYYY-MM-DD"),
					};
				});
			});

			const tableData = computed(() => {
				let list = props.report.assignee.map((assignee) => {
					let result = {
						member: assignee,
					};

					Object.keys(props.report.weeklyReport).map((yearWeek) => {
						const tasks =
							props.report.weeklyReport[yearWeek].assigneeTasks[
								assignee.id
							].tasks;
						let totalActualHours = 0;

						Object.keys(tasks).forEach((ticketKey) => {
							const task = tasks[ticketKey];
							totalActualHours +=
								task.proRateActualManHours != null
									? task.proRateActualManHours
									: 0;
						});

						result[yearWeek] = {
							total: totalActualHours.toFixed(2),
							taskList: Object.values(tasks),
						};
					});

					return result;
				});

				return _.sortBy(list, o => o.member.displayName);
			});

			function loadInfo(task) {
				dialogState.task = task;
				dialogState.open = true;
			}

			return {
				tableHeader,
				tableData,

				dialogState,
				loadInfo,

				height,
			};
		},
	};
</script>

<style scoped>
</style>