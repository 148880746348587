<template>
	<simple-loading
		:is-loading="isLoading"
		:is-load-failed="loadFail"
		:show-loading-card="true"
		@reload="fetchReport"
	>
		<el-card>
			<jira-weekly-time-record :report="reportRes" />

			<!-- <b-tabs align="left">
                <jira-weekly-time-record :report="reportRes" />


				<jira-weekly-time-record
					:task_hour="task_hour"
					:task_list="task_list"
				/>
				<jira-missing-time-record :task_list="task_list" />
			</b-tabs> -->
		</el-card>
	</simple-loading>
</template>

<script>
	import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
	import JiraWeeklyTimeRecord from "./JiraWeeklyTimeRecord.vue";
	import JiraMissingTimeRecord from "./JiraMissingTimeRecord.vue";
    import { ref, onMounted, watch, computed } from "@vue/composition-api";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
    import {
		getLocalDateTimeString,
		getZonedDatesFromFullDates,
	} from "@/utils/date-time-helper";
    import { BTabs } from 'bootstrap-vue';

	export default {
		components: {
			BTabs,

			SimpleLoading,
			JiraWeeklyTimeRecord,
			JiraMissingTimeRecord,
		},
		setup(props, ctx) {
            const isLoading = ref(true);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
            const reportRes = ref(null);

            onMounted(() => {
                fetchReport();
            })

            const fetchReport = async () => {
                isLoading.value = true;
				loadFail.value = false;

				try {
					const response = await fetcher(
						apis.jiraWeeklyTimeRecord,
						FetchMethod.GET,
					);

                    reportRes.value = response;
					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
            }

            return {
                isLoading,
                loadFail,
                fetchReport,

                reportRes
            }
        },
	};
</script>

<style scoped>
</style>